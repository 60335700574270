import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import TrackOrder from './track-order';
import GroupTrackOrder from './group-track-order';


const GroupOrder = (props: any) => {
    const {
      group,
      index,
      droppableTrackType
    } = props;  

    const [collapsed, setCollapsed] = useState<boolean>(true);

    const onSetCollabpsed = () => {
        setCollapsed(!collapsed);
        // if (collapsed) {
        //   setSelectedGroup(id);
        // } else {
        //   setSelectedGroup(null);
        // }
      };

return (
    <Draggable draggableId={"group_" + group.groupId} index={index} >
        {(provided: any) => (
            <div 
            style={{height: "30px"}}
                ref={provided.innerRef}
                {...provided.draggableProps}                
                >
                <div className={`group-header is-size-6 `} onClick={onSetCollabpsed} {...provided.dragHandleProps}>
                  <div className="track-order-icon">              
                      <i className="material-icons">reorder</i>
                  </div>
                  <div className="track-order-label">{group?.groupName}</div>
                  <div className="collapsible-icon">
                    <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={collapsed ? 0 : 2} />
                  </div>
                </div>  
                <div className="collapsible-content" style={{display: collapsed ? "none" : "inline-block", overflow: "visible" }}>
                    <Droppable droppableId={group.groupId} type={droppableTrackType}>
                        {(provided: any) => (
                        <div ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {group?.tracks?.map((track: any, trackIndex: number) => (
                                <GroupTrackOrder key={`${track.boreholeId}_${track.trackId}`} track={track} index={trackIndex} groupId={group?.groupId} />
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                </div>                
            </div>
        )}
    </Draggable>
)};

export default GroupOrder;
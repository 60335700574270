import { useEffect, useState } from 'react';
import { useImageTypesQuery, useTrackTypesHierarchyQuery } from '../../../services/trackTypeApi';
import ReactSelectField from '../../Input/react-select-field';
import SmallLoader from '../../small-loader';
import AddTrack from '../add-track';

const AddTracksToGroup = (props: any) => {

    const {             
      boreholeId, 
      clientId, 
      projectId, 
      boreholes,
      addTracks
    } = props;    

    const [selectedBoreholeId, setSelectedBoreholeId] = useState<any>(boreholeId);
    const [selectedTrackType, setSelectedTrackType] = useState<any>();
    const [selectedTrackCategory, setSelectedTrackCategory] = useState<any>(null);
    const [selectedTemplateTrackType, setSelectedTemplateTrackType] = useState(0);
    const [checkedTrackTypeIds, setCheckedTrackTypeIds] = useState<Array<string>>([]);
    const [checkedImageTypes, setCheckedImageTypes] = useState<Array<number>>([]);
    const [checkedLithologyTypes, setCheckedLithologyTypes] = useState<Array<number>>([]);
    const [checkedTextTypes, setCheckedTextTypes] = useState<Array<number>>([]);    
    
    const { data: trackTypesHierarchyData, isFetching: trackTypesHierarchyDataIsLoading } = useTrackTypesHierarchyQuery({ boreholeId: selectedBoreholeId, clientId, projectId }, { refetchOnMountOrArgChange: true });
    const { data: imageTypes, isLoading: imageTypesDataIsLoading } = useImageTypesQuery({ boreholeId, clientId, projectId, imageViewer: false }, { refetchOnMountOrArgChange: true });

    const boreholeOptions = boreholes?.map((borehole: any) => { return { value: borehole.id, label: borehole.name }} );    

    const onBoreholeChange = (selectedItem: any) => {          
      setSelectedBoreholeId(selectedItem.value);
    };

    useEffect(() => {      
      console.log("boreholeId", boreholeId);
      setSelectedBoreholeId(boreholeId);
    },[boreholeId]);

    const onAddTrackFormSubmit = () => {

      const allTrackTypes = trackTypesHierarchyData?.trackTypeCategories.reduce((previousValue : any, currentValue: any) => {
        if (currentValue.trackTypes) {
          previousValue.push(...currentValue.trackTypes);
        }
        return previousValue;
      }, []);
      
      const borehole = boreholes.find((b: any) => b.id == selectedBoreholeId);

      if (selectedTemplateTrackType === 0) { // graph        
        const selectedTrackTypes = checkedTrackTypeIds.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);          
          return { trackTypeId, trackName: trackType?.name, allowedCurveTypes: trackType?.allowedCurveTypes, units: trackType?.units, trackType: selectedTemplateTrackType }
        });        
        addTracks(selectedBoreholeId, borehole.name, borehole.projectName, selectedTrackTypes, selectedTrackCategory.name);        
        
      } else if (selectedTemplateTrackType === 1) { //image
        const selectedTrackTypes = checkedImageTypes.map((imageTypeValue: any) => {          
          const imageType = imageTypes.imageTypes.find((imageType: any) => imageType.value == imageTypeValue);
          return { imageType: imageTypeValue, trackName: imageType?.name, legendName: imageType?.legendName, legendRollover: imageType?.legendRollover, noImages: imageType?.noImages, trackType: selectedTemplateTrackType };
        });
        addTracks(selectedBoreholeId, borehole.name, borehole.projectName, selectedTrackTypes, null);        
        //dispatch(addImageTracks({imageTrackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds}));
      } else if (selectedTemplateTrackType === 3 || selectedTemplateTrackType === 4) { // cumulative || tadpole
        const selectedTrackTypes: any = checkedTrackTypeIds.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
          return { trackTypeId, displayName: trackType?.name, trackName: trackType?.name, allowedCurveTypes: trackType?.allowedCurveTypes, units: trackType?.units, trackType: selectedTemplateTrackType };
        });
        
        const trackLabel = selectedTrackTypes.map((curve: any) => curve.displayName).join(", ");
        
        const mappedTracks = [{
          boreholeName: borehole.name, 
          projectName: borehole.projectName,
          boreholeId: borehole.id,                         
          trackName: trackLabel, 
          label: trackLabel, 
          trackType: selectedTemplateTrackType,
          trackTypeId: selectedTrackTypes[0].trackTypeId,
          allowedCurveTypes: selectedTrackTypes[0].allowedCurveTypes, 
          units: selectedTrackTypes[0].units,
          curves: selectedTrackTypes.map((curve: any) => { return { 
            displayName: curve.displayName, 
            boreholeName: borehole.name, 
            boreholeId: borehole.id, 
            projectName: borehole.projectName,
            trackCategoryName: curve.trackCategoryName,
            trackTypeId: curve.trackTypeId,
            units: curve.units,
            allowedCurveTypes: curve.allowedCurveTypes
          }})
        }];        
        
        
        addTracks(selectedBoreholeId, borehole.name, borehole.projectName, mappedTracks, null);        
        // dispatch(addCumulativeTrack({ trackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds }));
        // dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
        //   clientId, 
        //   projectId,
        //   boreholeIds: selectedBoreholeIds,
        //   pixelHeight, 
        //   trackTypeIds: checkedTrackTypes, 
        //   startDepth, 
        //   endDepth, 
        //   byBoreholeDepth,
        //   depthUnit: internalDepthUnit }));
      // } else if (selectedTemplateTrackType === 4) { // tadpole
      //   const selectedTrackTypes = checkedTrackTypeIds.map((trackTypeId: any) => {
      //     const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
      //     return { trackTypeId, displayName: trackType?.name, trackName: trackType?.name };
      //   });
      //   addTracks(selectedBoreholeId, borehole.name, borehole.projectName, selectedTrackTypes, null);        
      //   // dispatch(addTadpoleTrack({ trackTypes: selectedTrackTypes, boreholeIds: selectedBoreholeIds }));
      //   // dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
      //   //   clientId, 
      //   //   projectId,
      //   //   boreholeIds: selectedBoreholeIds,
      //   //   pixelHeight, 
      //   //   trackTypeIds: checkedTrackTypes, 
      //   //   startDepth, 
      //   //   endDepth, 
      //   //   byBoreholeDepth,
      //   //   depthUnit: internalDepthUnit }));
      } else if (selectedTemplateTrackType === 5) { // lithology
        const selectedLithologyTypes = checkedLithologyTypes.map((trackTypeId: any) => {
          const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);          
          return { trackTypeId, trackName: trackType?.name, displayName: trackType?.name, trackType: selectedTemplateTrackType };
        });
        debugger;
        addTracks(selectedBoreholeId, borehole.name, borehole.projectName, selectedLithologyTypes, null);        
        // dispatch(addLithologyTrack({ trackTypes: selectedLithologyTypes, boreholeIds: selectedBoreholeIds }));
        
        // dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
        //   clientId, 
        //   projectId,
        //   boreholeIds: selectedBoreholeIds,
        //   pixelHeight, 
        //   trackTypeIds: checkedLithologyTypes, 
        //   startDepth, 
        //   endDepth, 
        //   byBoreholeDepth,
        //   depthUnit: internalDepthUnit }));
      } else if (selectedTemplateTrackType === 7) { // Text        
        const trackTypeId = checkedTextTypes[0];
        const textTrack = { trackTypeId: trackTypeId.toString(), trackName: "Description", displayName: "Description", trackType: selectedTemplateTrackType }
        addTracks(selectedBoreholeId, borehole.name, borehole.projectName, [textTrack], null);        
        // dispatch(addTextTrack({ trackTypeId, boreholeIds: selectedBoreholeIds }));        
        // dispatch(getMultipleBoreholeTrackDataForTrackTypes({ 
        //   clientId, 
        //   projectId,
        //   boreholeIds: selectedBoreholeIds,
        //   pixelHeight, 
        //   trackTypeIds: [trackTypeId], 
        //   startDepth, 
        //   endDepth, 
        //   byBoreholeDepth,
        //   depthUnit: internalDepthUnit }));
      }
      

      setCheckedTrackTypeIds([]);
      setCheckedImageTypes([]);
      setCheckedLithologyTypes([]);
    };
    
return(
  
  <>
  <ReactSelectField label="Boreholes" options={boreholeOptions} value={selectedBoreholeId} onChange={onBoreholeChange} />
  {(trackTypesHierarchyDataIsLoading || imageTypesDataIsLoading) && <SmallLoader/>}
  {(!trackTypesHierarchyDataIsLoading && !imageTypesDataIsLoading) && <AddTrack 
                    onAddTrackFormSubmit={onAddTrackFormSubmit}
                    selectedTrackType={selectedTrackType} 
                    setSelectedTrackType={setSelectedTrackType}
                    trackTypeCategories={trackTypesHierarchyData?.trackTypeCategories} 
                    trackTypesDataIsLoading={trackTypesHierarchyDataIsLoading}                            
                    checkedTrackTypes={checkedTrackTypeIds}
                    setCheckedTrackTypes={setCheckedTrackTypeIds} 
                    selectedTemplateTrackType={selectedTemplateTrackType}
                    setSelectedTemplateTrackType={setSelectedTemplateTrackType}
                    checkedImageTypes={checkedImageTypes}
                    setCheckedImageTypes={setCheckedImageTypes}
                    checkedLithologyTypes={checkedLithologyTypes}
                    setCheckedLithologyTypes={setCheckedLithologyTypes}
                    checkedTextTypes={checkedTextTypes}
                    setCheckedTextTypes={setCheckedTextTypes}
                    imageTypes={imageTypes?.imageTypes}
                    selectedTrackCategory={selectedTrackCategory}
                    setSelectedTrackCategory={setSelectedTrackCategory}                  
                    allowedTrackCategories={[0,1,2,3,4,5,6,7,8]}
    />}
    </>
)};

export default AddTracksToGroup;
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import '../panel.scss';
import './track-order-panel.scss';
import TrackOrder from './track-order';
import RightPanel from '../right-panel';
import GroupOrder from './group-order';
import CheckboxField from '../../Input/checkbox-field';

const TrackOrderPanel = (props: any) => {
    const {
      tracks,
      updateTrackOrder,
      updateGroupTrackOrder,
      updateGroupOrder      
    } = props;  
    
    const selectView = (state: RootState) => state.views;
    const {       
      view
    } = useSelector(selectView);

  const [applyToAll, setApplyToAll] = useState<boolean>(true);

  const groups = view.groups;
  const showGroups = groups && groups.length > 1;

  const droppableTrackType  = "track";
  const droppableGroupType  = "group";

  let allGroupsTheSame = true;
  
  if (showGroups) {
    for (let g = 0; g < groups.length - 1; g++) {
      const firstGroup = groups[g];
      const nextGroup = groups[g + 1];
      const firstGroupTracks = firstGroup.tracks;
      const nextGroupTracks = nextGroup.tracks;
      if (firstGroupTracks?.length != nextGroupTracks?.length) {
        allGroupsTheSame = false;
        break;
      }

      for (let t = 0; t < firstGroupTracks.length; t++) {                
        if (!(firstGroupTracks[t].trackId === nextGroupTracks[t].trackId && firstGroupTracks[t].boreholeId === firstGroup.boreholeId))
        {
          allGroupsTheSame = false;
          break;
        }
      }

      if (!allGroupsTheSame) {
        break;
      }
    }    
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (showGroups && type === droppableTrackType) {      
      updateGroupTrackOrder(source.droppableId, destination.droppableId, source.index, destination.index, applyToAll && allGroupsTheSame);
    } else if (showGroups && type === droppableGroupType) {      
      updateGroupOrder(source.index, destination.index);
    } else {      
      updateTrackOrder(source.index, destination.index);
    }
  }
  
return (
  <RightPanel title="Reorder" icon="Atlas Reorder Icon">
    <DragDropContext onDragEnd={onDragEnd}>
      {!showGroups &&
       <Droppable droppableId={"trackorder"} type={droppableTrackType}>
         {(provided: any) => (
          <div ref={provided.innerRef}
          {...provided.droppableProps}>
              {(tracks || []).map((track: any, index: number) => (
                 <TrackOrder key={track.id} track={track} index={index} />
              ))}
              {provided.placeholder}
          </div>
        )}
      </Droppable>}
      {showGroups &&
        <>
        {allGroupsTheSame && <div>
          <CheckboxField label="Apply to all groups" className="mt-2 ml-2" checked={applyToAll} onChange={setApplyToAll} />
        </div>}
          <Droppable droppableId={"groupOrder"} type={droppableGroupType}>
            {(provided: any) => (
              <div ref={provided.innerRef}
                {...provided.droppableProps}>
                  {(groups || []).map((group: any, index: number) => (
                    <GroupOrder key={group.groupId} group={group} index={index} droppableTrackType={droppableTrackType} />
                  ))}
                  {provided.placeholder}
              </div>
            )}
          </Droppable>
        </>}
      
    </DragDropContext>
   </RightPanel>
)};

export default TrackOrderPanel;
import DepthTrack from '../../components/log-viewer/depth-track';
import useWindowSize from '../../components/useWindowResize';
import './trackData.scss';
import getConfig from '../../app/config';
import auth from '../../components/auth';
import { useEffect, useRef, useState } from 'react';
import Tracks from './Tracks';

export default function TrackData(props : any) {

    const { 
      clientId, 
      projectId, 
      boreholeId, 
      startDepth, 
      endDepth, 
      templateId, 
      byBoreholeDepth,
      trackData,
      sortedTrackData,
      sortedGroupTrackData,
      template,
      showView,
      view,
      updateCurve,
      updateCurves,      
      setShowGridlines,
      setScaleType,
      setTrackWidth,
      depthUnit,
      removeTrack,      
      trackTypes,
      editMode,
      depthMajorIntervals,
      setDepthMajorIntervals,
      showDepthGridLines,
      setShowDepthGridlines,
      showDepthMinorIntervals,
      setShowDepthMinorIntervals,
      depthMinorIntervals,
      setDepthMinorIntervals,
      deletedCurves,
      selectedTrackCategory,
      setSelectedTrackCategory,
      trackHeaderClick,
      setMetaDataDepth,
      deselectAllTracks,
      selectLithology,
      addLithology
     } = props;

    const windowSize: any = useWindowSize();

    const container: any = useRef();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);  

  function debounce(func: any, wait: any, immediate: any) {
    var timeout: any;
    return function (this: any) {
      var context : any = this,
        args: any = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  useEffect(() => {
    const handleWindowResize = debounce(function handleResize() {
      setWindowHeight(window.innerHeight);
    }, 250, null);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  if (!trackData) {
    return null;
  }
  
    const apiUrl = getConfig().apiUrl;

    const imageAuthToken = auth.getTokenForImages();
    
    const onUpdateCurve = (templateTrackId: string, curve: any) => {
      updateCurve(templateTrackId, curve);
    } 

    const onUpdateCurves = (templateTrackId: string, curves: any) => {
      updateCurves(templateId, templateTrackId, curves);
    }

    const onSetShowGridlines = (templateTrackId: string, trackTypeId: any, show: boolean) => {
      setShowGridlines(templateId, templateTrackId, trackTypeId, show);
    }

    const onSetScaleType = (templateTrackId: string, trackTypeId: any, scaleType: number) => {
      setScaleType(templateId, templateTrackId, trackTypeId, scaleType);
    }
    
    const onSetTrackWidth = (templateTrackId: string, width: number) => {
      setTrackWidth(templateId, templateTrackId, width);
    }

    const cumulativeHeaderHeight = 20.86;
    const headerHeight = 67;
    const headerBottomPadding = 13;
    const imageWithoutLegendHeaderHeight = 54;
    const imageWithLegendHeaderHeight = 65;

    let maxGraphCurves = sortedTrackData ? Math.max(...sortedTrackData.filter((track: any) => track.trackType !== 3 && track.trackType !== 4 && track.trackType !== 7 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0)) : 0;
    let maxCumulativeCurves = sortedTrackData ? Math.max(...sortedTrackData.filter((track: any) => track.trackType === 3 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0)) : 0;
    let maxLithologyCodes = sortedTrackData ? Math.max(...sortedTrackData.filter((track: any) => track.trackType === 5).map((track: any) => track?.codesAndColors.length ?? 0)) : 0;

    if (showView) {
      sortedGroupTrackData?.forEach((group: any) => {
        const groupMasGraphCurves = Math.max(...group.tracks.filter((track: any) => track.trackType !== 3 && track.trackType !== 4 && track.trackType !== 7 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0));
        if (groupMasGraphCurves > maxGraphCurves) {
          maxGraphCurves = groupMasGraphCurves;
        }

        const groupMaxCumulativeCurves = Math.max(...group.tracks.filter((track: any) => track.trackType === 3 && track?.datas?.some((data: any) => !data.noData)).map((track: any) => track?.curves?.length ?? 0));
        if (groupMaxCumulativeCurves > maxCumulativeCurves) {
          maxCumulativeCurves = groupMaxCumulativeCurves;
        }

        const groupMaxLithologyCodes = Math.max(...group.tracks.filter((track: any) => track.trackType === 5).map((track: any) => track?.codesAndColors.length ?? 0));
        if (groupMaxLithologyCodes > maxLithologyCodes) {
          maxLithologyCodes = groupMaxLithologyCodes;
        }
      });
    }
    
    const containsImageWithLegend = sortedTrackData?.some((track: any) => track.trackType === 1 && track.legend && !track.legendRollover);
        
    if (maxCumulativeCurves < 0) {
      maxCumulativeCurves = 0;
    }

    let minHeaderHeight = 0;
    if (containsImageWithLegend) {
      minHeaderHeight = imageWithLegendHeaderHeight;
    } else {
      minHeaderHeight = imageWithoutLegendHeaderHeight;
    }

    const curveHeights = (maxGraphCurves * headerHeight) - headerBottomPadding;
    if (curveHeights > minHeaderHeight) {
      minHeaderHeight = curveHeights;
    }

    const minCumulativeHeaderHeight = (maxCumulativeCurves * cumulativeHeaderHeight);

    const minLithologyHeaderHeight = (maxLithologyCodes * cumulativeHeaderHeight) + 19.2;
    
    if (minCumulativeHeaderHeight > minHeaderHeight) {
      minHeaderHeight = minCumulativeHeaderHeight;
    }

    if (minLithologyHeaderHeight > minHeaderHeight) {
      minHeaderHeight = minLithologyHeaderHeight;
    }
       
    const topMargin = template?.showValueAxisAnnotation ? 35 : 10;

    const trackTop = minHeaderHeight;// + topMargin;
    let imageHeaderHeight = 0;
    const imageCurveHeaderHeight = (minHeaderHeight + 123);
    if (containsImageWithLegend) {          
      imageHeaderHeight = imageWithLegendHeaderHeight + 125;
    } else {
      imageHeaderHeight = imageWithoutLegendHeaderHeight + 125;
    }

    if (imageCurveHeaderHeight > imageHeaderHeight) {
      imageHeaderHeight = imageCurveHeaderHeight;
    }
    
    const boreholeLabelHeight = 35;
    const imageHeight = windowSize.height - imageHeaderHeight - topMargin - (showView ? boreholeLabelHeight : 0);
    const availableHeight = windowHeight - 56 - 57 - (showView ? boreholeLabelHeight : 0);
    const depthTrackAvailableHeight = windowHeight - 56 - 57;
    const depthTrackTopMargin = topMargin + (showView ? boreholeLabelHeight : 0);

    return (        
        <>
           <div className="log-track-container">
            
            {true &&
            <>
            {startDepth != null && endDepth != null && <DepthTrack 
                startDepth={startDepth} 
                endDepth={endDepth} 
                depthUnit={depthUnit} 
                minHeaderHeight={minHeaderHeight}
                headerBottomPadding={headerBottomPadding}
                editMode={editMode}      
                availableHeight={depthTrackAvailableHeight}
                trackTop={trackTop}
                topMargin={depthTrackTopMargin}
                depthMajorIntervals={depthMajorIntervals} 
                setDialogMajorIntervals={setDepthMajorIntervals}
                showDepthGridLines={showDepthGridLines}
                setShowDepthGridlines={setShowDepthGridlines}
                depthMinorIntervals={depthMinorIntervals}
                setDepthMinorIntervals={setDepthMinorIntervals}
                showDepthMinorIntervals={showDepthMinorIntervals}
                setShowDepthMinorIntervals={setShowDepthMinorIntervals}
                deselectAllTracks={deselectAllTracks}
                showValueAxisAnnotation={template?.showValueAxisAnnotation} /> }

              <div ref={container} className="columns flex-container" style={{ overflowY: "hidden" }}>

              {showView && <>
                {sortedGroupTrackData?.map((group: any, index: number) => {                  
                  return (<div key={group.groupId} className="column flex-item group-outline">
                    <div className='borehole-header-container' style={{backgroundColor: group.color, textAlign: "center", minHeight: boreholeLabelHeight }}>
                      <div className='borehole-header'>{group.groupName}</div>
                    </div>                    
                    <div ref={container} className="columns flex-container" style={{ overflowY: "hidden" }}>
                    <Tracks
                      clientId={clientId}
                      projectId={projectId}
                      boreholeId={boreholeId}
                      startDepth={startDepth}
                      endDepth={endDepth}
                      byBoreholeDepth={byBoreholeDepth}
                      sortedTrackData={group.tracks}
                      template={template}
                      depthUnit={depthUnit}
                      removeTrack={removeTrack}
                      trackTypes={trackTypes}
                      editMode={editMode}
                      depthMajorIntervals={depthMajorIntervals}
                      showDepthGridLines={showDepthGridLines}
                      showDepthMinorIntervals={showDepthMinorIntervals}
                      depthMinorIntervals={depthMinorIntervals}
                      deletedCurves={deletedCurves}
                      selectedTrackCategory={selectedTrackCategory}
                      setSelectedTrackCategory={setSelectedTrackCategory}
                      trackHeaderClick	={trackHeaderClick}
                      setMetaDataDepth={setMetaDataDepth}
                      selectLithology={selectLithology}
                      addLithology={addLithology}
                      topMargin={topMargin}
                      availableHeight={availableHeight}
                      onUpdateCurve={onUpdateCurve}
                      onUpdateCurves={onUpdateCurves}
                      onSetShowGridlines={onSetShowGridlines}
                      onSetScaleType={onSetScaleType}
                      onSetTrackWidth={onSetTrackWidth}
                      headerHeight={headerHeight}
                      minHeaderHeight={minHeaderHeight}
                      headerBottomPadding={headerBottomPadding}
                      windowSize={windowSize}
                      container={container}
                      imageHeight={imageHeight}
                      apiUrl={apiUrl}
                      imageAuthToken={imageAuthToken}                  
                      cumulativeHeaderHeight={cumulativeHeaderHeight}
                    />
                    </div>
                  </div>)
                })}
              </> }
              {!showView && <>
                  <Tracks
                    clientId={clientId}
                    projectId={projectId}
                    boreholeId={boreholeId}
                    startDepth={startDepth}
                    endDepth={endDepth}
                    byBoreholeDepth={byBoreholeDepth}
                    sortedTrackData={sortedTrackData}
                    template={template}
                    depthUnit={depthUnit}
                    removeTrack={removeTrack}
                    trackTypes={trackTypes}
                    editMode={editMode}
                    depthMajorIntervals={depthMajorIntervals}
                    showDepthGridLines={showDepthGridLines}
                    showDepthMinorIntervals={showDepthMinorIntervals}
                    depthMinorIntervals={depthMinorIntervals}
                    deletedCurves={deletedCurves}
                    selectedTrackCategory={selectedTrackCategory}
                    setSelectedTrackCategory={setSelectedTrackCategory}
                    trackHeaderClick	={trackHeaderClick}
                    setMetaDataDepth={setMetaDataDepth}
                    selectLithology={selectLithology}
                    addLithology={addLithology}
                    topMargin={topMargin}
                    availableHeight={availableHeight}
                    onUpdateCurve={onUpdateCurve}
                    onUpdateCurves={onUpdateCurves}
                    onSetShowGridlines={onSetShowGridlines}
                    onSetScaleType={onSetScaleType}
                    onSetTrackWidth={onSetTrackWidth}
                    headerHeight={headerHeight}
                    minHeaderHeight={minHeaderHeight}
                    headerBottomPadding={headerBottomPadding}
                    windowSize={windowSize}
                    container={container}
                    imageHeight={imageHeight}
                    apiUrl={apiUrl}
                    imageAuthToken={imageAuthToken}                  
                    cumulativeHeaderHeight={cumulativeHeaderHeight}
                    />
              
                {/* {(sortedTrackData || []).map((track: any, index: number) => { 
                  if (track.trackType === 0) {
                    return (
                      <>
                        {!track.noData &&
                        <div key={track.id} className="column flex-item">
                          <Chart 
                              id={track.id} 
                              displayOrder={track.displayOrder}
                              trackWidth={track.trackWidth}
                              topMargin={topMargin}
                              availableHeight={availableHeight}
                              index={index} 
                              curves={track.curves}                                  
                              chartData={track} 
                              startDepth={startDepth} 
                              endDepth={endDepth} 
                              depthUnit={depthUnit}
                              showYAxis={false} 
                              updateCurve={onUpdateCurve}
                              updateCurves={onUpdateCurves}
                              lineColor={track.lineColor}
                              fillColor={track.fillColor}
                              pointColor={track.pointColor}
                              lineStyle={track.lineStyle}
                              fillStyle={track.fillStyle}                        
                              pointStyle={track.pointStyle}
                              lineSize={track.lineSize}
                              fillSize={track.fillSize}                        
                              pointSize={track.pointSize}                            
                              showLine={track.showLine}
                              showFill={track.showFill}
                              showPoints={track.showPoints}
                              xScaleMinimum={track.xScaleMinimum}
                              xScaleMaximum={track.xScaleMaximum}
                              manualScaleXMinimum={track.manualScaleXMinimum}
                              manualScaleXMaximum={track.manualScaleXMaximum}
                              majorIntervals={track.majorIntervals}
                              minorIntervals={track.minorIntervals}
                              manualMajorIntervals={track.manualMajorIntervals}
                              manualMinorIntervals={track.manualMinorIntervals}
                              showGridlines={track.showGridlines}
                              showValueAxisAnnotation={track.showValueAxisAnnotation}
                              scaleType={track.scaleType}                            
                              setShowGridlines={onSetShowGridlines}                            
                              setScaleType={onSetScaleType}
                              setTrackWidth={onSetTrackWidth}
                              removeTrack={removeTrack}
                              trackTypes={trackTypes}
                              editMode={editMode}
                              depthMajorIntervals={depthMajorIntervals}
                              showDepthGridLines={showDepthGridLines}
                              deletedCurves={deletedCurves}
                              selectedTrackCategory={selectedTrackCategory}
                              setSelectedTrackCategory={setSelectedTrackCategory}
                              headerHeight={headerHeight}
                              minHeaderHeight={minHeaderHeight}
                              headerBottomPadding={headerBottomPadding}
                              depthMinorIntervals={depthMinorIntervals}
                              showDepthMinorIntervals={showDepthMinorIntervals}
                              windowWidth={windowSize.width}
                              trackHeaderClick={() => trackHeaderClick(track)}
                              parentRef={container}
                              setMetaDataDepth={setMetaDataDepth}
                            />          
                        </div> }
                      </>)

                    } else if (track.trackType === 1) {
                      return (
                        <div key={track.id} className="column flex-item">
                          <ImageTrack 
                            id={track.id} 
                            imageHeight= {imageHeight}
                            availableHeight={availableHeight}
                            displayOrder={track.displayOrder}
                            width={track.width}
                            index={index}
                            depthUnit={depthUnit}
                            label={track.label} 
                            legend={track.legend}
                            legendRollover={track.legendRollover}
                            apiUrl={apiUrl} 
                            clientId={clientId}
                            projectId={projectId} 
                            boreholeId={boreholeId}
                            startDepth={startDepth} 
                            endDepth={endDepth} 
                            imageType={track.imageType} 
                            byBoreholeDepth={byBoreholeDepth} 
                            authToken={imageAuthToken}                           
                            removeTrack={removeTrack}
                            editMode={editMode}
                            headerHeight={headerHeight}
                            minHeaderHeight={minHeaderHeight}
                            headerBottomPadding={headerBottomPadding}
                            topMargin={topMargin}
                            trackWidth={track.trackWidth}                          
                            setTrackWidth={onSetTrackWidth}
                            trackHeaderClick={() => trackHeaderClick(track)}
                            scrollIntoView={track.scrollIntoView}
                            isSelected={track.isSelected}
                            showValueAxisAnnotation={template?.showValueAxisAnnotation}
                            noImage={track.noImage}
                            needsRegenerating={track.needsRegenerating}
                          />
                        </div>
                        )
                    } else  if (track.trackType === 3) { // cumulative
                      return (
                        <>
                          {!track.noData &&
                          <div key={track.id} className="column flex-item">
                            <CumulativeChart 
                                id={track.id} 
                                displayOrder={track.displayOrder}
                                trackWidth={track.trackWidth}
                                availableHeight={availableHeight}
                                topMargin={topMargin}
                                index={index} 
                                curves={track.curves}                                    
                                track={track} 
                                startDepth={startDepth} 
                                endDepth={endDepth} 
                                depthUnit={depthUnit}
                                showYAxis={false} 
                                updateCurve={onUpdateCurve}                       
                                lineColor={track.lineColor}
                                fillColor={track.fillColor}
                                pointColor={track.pointColor}
                                lineStyle={track.lineStyle}
                                fillStyle={track.fillStyle}                        
                                pointStyle={track.pointStyle}
                                lineSize={track.lineSize}
                                fillSize={track.fillSize}                        
                                pointSize={track.pointSize}                            
                                showLine={track.showLine}
                                showFill={track.showFill}
                                showPoints={track.showPoints}
                                xScaleMinimum={track.xScaleMinimum}
                                xScaleMaximum={track.xScaleMaximum}
                                manualScaleXMinimum={track.manualScaleXMinimum}
                                manualScaleXMaximum={track.manualScaleXMaximum}
                                majorIntervals={track.majorIntervals}
                                minorIntervals={track.minorIntervals}
                                manualMajorIntervals={track.manualMajorIntervals}
                                manualMinorIntervals={track.manualMinorIntervals}
                                showGridlines={track.showGridlines}
                                showValueAxisAnnotation={track.showValueAxisAnnotation}
                                scaleType={track.scaleType}                            
                                setShowGridlines={onSetShowGridlines}
                                setScaleType={onSetScaleType}
                                setTrackWidth={onSetTrackWidth}
                                removeTrack={removeTrack}
                                trackTypes={trackTypes}
                                editMode={editMode}
                                headerHeight={cumulativeHeaderHeight}
                                minHeaderHeight={minHeaderHeight}
                                headerBottomPadding={headerBottomPadding}
                                depthMajorIntervals={depthMajorIntervals}
                                showDepthGridLines={showDepthGridLines}
                                selectedTrackCategory={selectedTrackCategory}
                                setSelectedTrackCategory={setSelectedTrackCategory}
                                depthMinorIntervals={depthMinorIntervals}
                                showDepthMinorIntervals={showDepthMinorIntervals}
                                trackHeaderClick={() => trackHeaderClick(track)}
                                parentRef={container}
                                setMetaDataDepth={setMetaDataDepth}
                              />          
                          </div> }
                        </>)
    
                      }
                      else  if (track.trackType === 4) { // tadpole
                        return (
                          <>
                            {!track.noData &&
                            <div key={track.id} className="column flex-item">
                              <TadpoleChart 
                                  id={track.id} 
                                  displayOrder={track.displayOrder}
                                  trackWidth={track.trackWidth}
                                  availableHeight={availableHeight}
                                  topMargin={topMargin}
                                  index={index} 
                                  curves={track.curves}                                    
                                  track={track} 
                                  startDepth={startDepth} 
                                  endDepth={endDepth} 
                                  depthUnit={depthUnit}
                                  showYAxis={false} 
                                  updateCurve={onUpdateCurve}                
                                  lineColor={track.lineColor}
                                  fillColor={track.fillColor}
                                  pointColor={track.pointColor}
                                  lineStyle={track.lineStyle}
                                  fillStyle={track.fillStyle}                        
                                  pointStyle={track.pointStyle}
                                  lineSize={track.lineSize}
                                  fillSize={track.fillSize}                        
                                  pointSize={track.pointSize}                            
                                  showLine={track.showLine}
                                  showFill={track.showFill}
                                  showPoints={track.showPoints}
                                  xScaleMinimum={track.xScaleMinimum}
                                  xScaleMaximum={track.xScaleMaximum}
                                  manualScaleXMinimum={track.manualScaleXMinimum}
                                  manualScaleXMaximum={track.manualScaleXMaximum}
                                  majorIntervals={track.majorIntervals}
                                  minorIntervals={track.minorIntervals}
                                  manualMajorIntervals={track.manualMajorIntervals}
                                  manualMinorIntervals={track.manualMinorIntervals}
                                  showGridlines={track.showGridlines}
                                  showValueAxisAnnotation={track.showValueAxisAnnotation}
                                  scaleType={track.scaleType}                            
                                  setShowGridlines={onSetShowGridlines}
                                  setScaleType={onSetScaleType}
                                  setTrackWidth={onSetTrackWidth}
                                  removeTrack={removeTrack}
                                  trackTypes={trackTypes}
                                  editMode={editMode}
                                  headerHeight={headerHeight}
                                  minHeaderHeight={minHeaderHeight}
                                  headerBottomPadding={headerBottomPadding}
                                  depthMajorIntervals={depthMajorIntervals}
                                  showDepthGridLines={showDepthGridLines}
                                  selectedTrackCategory={selectedTrackCategory}
                                  setSelectedTrackCategory={setSelectedTrackCategory}
                                  depthMinorIntervals={depthMinorIntervals}
                                  showDepthMinorIntervals={showDepthMinorIntervals}
                                  trackHeaderClick={() => trackHeaderClick(track)}
                                  parentRef={container}
                                  setMetaDataDepth={setMetaDataDepth}
                                />          
                            </div> }
                          </>)
      
                        }
                        else  if (track.trackType === 5) { // lithology                        
                          return (
                            <>
                              <div key={track.id} className="column flex-item">
                                <Lithology 
                                    id={track.id} 
                                    displayOrder={track.displayOrder}
                                    trackWidth={track.trackWidth}
                                    availableHeight={availableHeight}
                                    topMargin={topMargin}
                                    index={index} 
                                    curves={track.curves}                                    
                                    track={track} 
                                    startDepth={startDepth} 
                                    endDepth={endDepth} 
                                    depthUnit={depthUnit}
                                    showYAxis={false}                                   
                                    xScaleMinimum={track.xScaleMinimum}
                                    xScaleMaximum={track.xScaleMaximum}
                                    manualScaleXMinimum={track.manualScaleXMinimum}
                                    manualScaleXMaximum={track.manualScaleXMaximum}
                                    majorIntervals={track.majorIntervals}
                                    minorIntervals={track.minorIntervals}
                                    manualMajorIntervals={track.manualMajorIntervals}
                                    manualMinorIntervals={track.manualMinorIntervals}
                                    showGridlines={track.showGridlines}
                                    showValueAxisAnnotation={track.showValueAxisAnnotation}
                                    scaleType={track.scaleType}                            
                                    setShowGridlines={onSetShowGridlines}
                                    setScaleType={onSetScaleType}
                                    setTrackWidth={onSetTrackWidth}
                                    removeTrack={removeTrack}
                                    trackTypes={trackTypes}
                                    editMode={editMode}
                                    headerHeight={cumulativeHeaderHeight}
                                    minHeaderHeight={minHeaderHeight}
                                    headerBottomPadding={headerBottomPadding}
                                    depthMajorIntervals={depthMajorIntervals}
                                    showDepthGridLines={showDepthGridLines}
                                    depthMinorIntervals={depthMinorIntervals}
                                    showDepthMinorIntervals={showDepthMinorIntervals}
                                    trackHeaderClick={() => trackHeaderClick(track)}
                                    parentRef={container}
                                    setMetaDataDepth={setMetaDataDepth}
                                    selectLithology={selectLithology}
                                    addLithology={addLithology}
                                    trackEditMode={track.trackEditMode}
                                  />          
                              </div>
                            </>)      
                          }
                          else  if (track.trackType === 7) { // text
                            return (
                              <>
                                <div key={track.id} className="column flex-item">
                                <TextTrack 
                                    id={track.id} 
                                    displayOrder={track.displayOrder}
                                    trackWidth={track.trackWidth}
                                    availableHeight={availableHeight}
                                    topMargin={topMargin}
                                    index={index}                                   
                                    track={track} 
                                    startDepth={startDepth} 
                                    endDepth={endDepth} 
                                    depthUnit={depthUnit}
                                    showYAxis={false}                                   
                                    xScaleMinimum={track.xScaleMinimum}
                                    xScaleMaximum={track.xScaleMaximum}
                                    manualScaleXMinimum={track.manualScaleXMinimum}
                                    manualScaleXMaximum={track.manualScaleXMaximum}
                                    majorIntervals={track.majorIntervals}
                                    minorIntervals={track.minorIntervals}
                                    manualMajorIntervals={track.manualMajorIntervals}
                                    manualMinorIntervals={track.manualMinorIntervals}
                                    showGridlines={track.showGridlines}
                                    showValueAxisAnnotation={track.showValueAxisAnnotation}
                                    scaleType={track.scaleType}                            
                                    setShowGridlines={onSetShowGridlines}
                                    setScaleType={onSetScaleType}
                                    setTrackWidth={onSetTrackWidth}
                                    removeTrack={removeTrack}
                                    trackTypes={trackTypes}
                                    editMode={editMode}
                                    headerHeight={cumulativeHeaderHeight}
                                    minHeaderHeight={minHeaderHeight}
                                    headerBottomPadding={headerBottomPadding}
                                    depthMajorIntervals={depthMajorIntervals}
                                    showDepthGridLines={showDepthGridLines}
                                    depthMinorIntervals={depthMinorIntervals}
                                    showDepthMinorIntervals={showDepthMinorIntervals}
                                    trackHeaderClick={() => trackHeaderClick(track)}
                                    parentRef={container}
                                    setMetaDataDepth={setMetaDataDepth}
                                    selectLithology={selectLithology}
                                    addLithology={addLithology}
                                    trackEditMode={track.trackEditMode}
                                  />   
                                </div>
                              </>)      
                            }
                  }
                )} */}
              </> }
            </div>   
            </>}       
           </div>
        </>
    )
  }
  